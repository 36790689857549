import React from 'react';
import DocumentPage, { DocumentPageContext } from '../components/DocumentPage';
import WorkorderCard from '../components/WorkorderCard';
import { PageProps } from 'gatsby';
import { WorkorderBM } from '@bm-js/h2o-shared';

const context: DocumentPageContext<WorkorderBM> = {
  documentName: 'Arbetsorder',
  documentNameSpecific: 'Arbetsordern',
  documentKey: 'workorder',
  documentKeyPlural: 'workorders',
  documentNamePlural: 'Arbersordrar',
  internalPersonKey: 'technicians',
  contactPersonKey: 'orderPersons',
  cardComponent: WorkorderCard,
  internalPersonKeyRequired: false,
  cardGridTemplate: '2fr 2fr 2fr 1fr 1fr 20px',
  cardHeaders: [
    {
      text: 'Startdatum',
      mobile: true,
    },
    {
      text: 'Kontaktpersoner',
    },
    {
      text: 'Ansvariga tekniker',
    },
    {
      text: 'Antal rader',
    },
    {
      text: 'Status',
      mobile: true,
    },
  ],
};

const WorkordersPage = ({ location }: PageProps) => {
  const props = { location, context };
  return <DocumentPage {...props} />;
};

export default WorkordersPage;
